/* ApprovalTable.css */

.approval-table-container {
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-table {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
}

.custom-table th.ant-table-cell {
  background-color: #f0f0f0;
  font-weight: bold;
}

.custom-table tbody tr:hover {
  background-color: #f5f5f5;
}
